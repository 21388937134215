<template>
  <div class="check-terms-container">
    <el-dialog
      :visible.sync="dialogVisible_checkTerms"
      :modal="false"
      class="check-terms-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose"
      :show-close="false"
      width="80%"
      top="5vh"
      center
    >
      <div slot="title" class="dialog-title">
        <div class="">{{ $t('InfoaAree.Title_Subject') }}</div>
      </div>
      <div ref="dialogContent" class="infoagree-content">
            
        <PersonalInfoAgree />
        <el-checkbox 
          @change="handleCheckboxChange"
        >
          {{ $t('Register.IReadAndAgree') }}
        </el-checkbox>
        <div class="summit-button-container">
          <el-button
            :disabled="disabledConfirmButton"
            type="button"
            @click.native.prevent="handleSummitInfoagree"
          >
            {{ $t('general.Confirm') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PersonalInfoAgree from '@/components/PersonalInfoAgree'
export default {
  name: 'CheckTermsVersion',
  components: {
    PersonalInfoAgree
  },
  props: {
    isCheckTermsOfUse: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogVisible_checkTerms: true,
      disabledConfirmButton: true
    }
  },
  methods: {
    handleCheckboxChange(checkedItems) {
      if (checkedItems) {
        this.disabledConfirmButton = false
      } else {
        this.disabledConfirmButton = true
      }
    },
    handleSummitInfoagree() {
      this.$emit('handleSummitInfoagree', 'checkTerms')
    },
    handleClose() {
      this.$router.push({ name: 'SportData' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.check-terms-container{
    .check-terms-dialog{
      background-color: $background_blue;
    }
    .summit-button-container{
      text-align: center;
      margin-top: 30px;
    }
    .el-button {
      width: 80%;
      background-color: $background_blue;
      color: $text_light;
      &.is-disabled {
        background-color: #6c6c6c;
      }
    }
    .infoagree-content {
      @media (max-width: 769px){
        .el-checkbox, .el-checkbox__input{
          white-space: initial;
          ::v-deep .el-checkbox__inner{
            top: 30px;
          }
        }
        ::v-deep .el-checkbox__label{
          padding-left: 20px;
        }
      }
    }
  }

</style>
