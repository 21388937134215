<template>
  <div class="verification-code">
    <p class="code" :style="randomStyle">{{ code }}</p>
    <el-button
      type="button"
      class="verification-change-button"
      tabindex="0"  
      @click.native="getCode"
    >
      {{ $t('general.ChangeVerificationCode') }}
    </el-button>
  </div>
</template>
<script>
export default {
  name: 'VerifyComponent',
  data() {
    this.codeLength = 6
    this.defaultFontColor = ['blue', 'yellow', 'red', 'green', 'orange']
    this.defaultBackgroundColor = ['yellow', 'red', 'black', 'orange', 'blue']
    this.defaultLetterSpacing = ['2px', '8px', '5px', '3px', '6px']
    this.random = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    return {
      code: '',
      randomStyle: {}
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      let code = ''
      this.getRandomStyle()
      for (let i = 0; i < this.codeLength; i++) {
        const number = Math.floor(Math.random() * this.random.length)
        code += this.random[number]
      }
      this.code = code
    },
    getRandomStyle() {
      const randomNumber = Math.floor(Math.random() * (this.defaultFontColor.length))
      this.randomStyle = {
        color: this.defaultFontColor[randomNumber],
        'background-color': this.defaultBackgroundColor[randomNumber],
        'letter-spacing': this.defaultLetterSpacing[randomNumber]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/styles/index.scss";
.verification-code {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  @media (max-width: 576px) {
    padding-left: 0px;
  }
  > .code {
    border-radius: 3px;
    font-family: Arial, sans-serif;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    border: 0;
    letter-spacing: 2px;
    // padding: 4px;
    width: 130px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
  }
  .verification-change-button{
    color: $text_light;
    background-color:  $background_dark;
    border-color: $background_dark;
  }
  .el-button:focus{
    border: 3px solid $input_focus_gold;
  }
}
</style>
