<template>
  <div class="forget-password-container">
    <el-button
      type="text"
      icon="el-icon-info"
      class="forget-password-button"
      @click="dialogVisible_forgetPassword = true"
    >
      {{ $t('general.ForgetPassword') }}
    </el-button>
    <el-dialog :title="$t('general.ForgetPassword')" :visible.sync="dialogVisible_forgetPassword">
      <el-form
        ref="forgetPasswordForm"
        :model="forgetPasswordForm"
        :rules="forgetPasswordRules"
        class="forget-password-form"
        label-position="top"
      >
        <el-form-item
          v-for="(item, index) in formInputField"
          :key="index"
          :label="$t(`general.${item.label}`)"
          :prop="item.prop"
        >
          <el-input
            v-model="forgetPasswordForm[item.prop]"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_forgetPassword = false">{{ $t('general.Cancel') }}</el-button>
        <el-button type="primary" @click="handleForgetPassword('forgetPasswordForm')">{{ $t('general.Confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { forgetPassword } from '@/api/member'
import { SuccessMessage } from '@/Mixins'
export default {
  name: 'ForgetPassword',
  mixins: [SuccessMessage],
  data() {
    return {
      dialogVisible_forgetPassword: false,
      forgetPasswordRules: {
        login: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('general.PleaseInput') + ' ' + this.$t('general.Account')
          }
        ],
        email: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('general.PleaseInput') + ' ' + this.$t('general.Email')
          },
          { 
            type: 'email',
            message: this.$t('Notify.pleaseInput') + this.$t('accountManagement.email'),
            trigger: 'blur'
          }
        ],
        contact_name: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('general.PleaseInput') + ' ' + this.$t('general.ContactName')
          }
        ]
      },
      formInputField: [
        {
          label: 'Account',
          prop: 'login'
        },
        {
          label: 'Email',
          prop: 'email'
        },
        {
          label: 'ContactName',
          prop: 'contact_name'
        },
        {
          label: 'Phone',
          prop: 'phone'
        }
      ],
      forgetPasswordForm: {
        login: '',
        email: '',
        contact_name: '',
        phone: ''
      }
    }
  },
  methods: {
    async fetchForgetPassword() {
      try {
        await forgetPassword(this.forgetPasswordForm)
        this.dialogVisible_forgetPassword = false
        this.showSuccessMessage(this.$t('Notify.forgetPasswordSuccess'))
      } catch (error) {
        console.error(error)
      }
    },
    handleForgetPassword(forgetPasswordForm) {
      this.$refs[forgetPasswordForm].validate((valid) => {
        if (valid) {
          this.fetchForgetPassword()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.forget-password-container{
  .el-dialog{
    .forget-password-form{
      .el-form-item{
        ::v-deep .el-form-item__label{
          color: $text_dark;
        }
      }
    }
  }
  .forget-password-button{
    color: $text_light;
    font-weight: normal;
    @media (max-width: 576px) {
      color: initial;
    }
    
  }
  .forget-password-button:hover {
    font-weight: bold;
  }
}
</style>
