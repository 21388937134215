<template>
  <div class="login-outer-container">
    
    <!-- 個資同意書勾選 -->
    <template v-if="checkTermsVersion">
      <CheckTermsVersion
        :is-check-terms-of-use="isCheckTermsOfUse"
        @handleSummitInfoagree="handleUpdateMember"
      />
    </template>
    <!-- 首次登入更換密碼 -->
    <template v-else-if="forceChangePwd">
      <div class="change-container">
        <div class="form-container change-password">
          <h3 class="title">{{ $t('general.ChangePasswordTitle') }}</h3>
          <el-form
            ref="pwdInfoForm"
            :model="pwdInfoForm"
            :rules="pwdRules"
            class="change-form"
            auto-complete="on"
            label-position="left"
          >
            <el-form-item prop="oldPwd">
              <span class="svg-container">
                <em class="ri-user-3-fill" />
                {{ $t('general.oldPwd') }}
              </span>
              <el-input
                ref="refpwd1"
                v-model="pwdInfoForm.oldPwd"
                :placeholder="$t('accountManagement.oldPassword')"
                :aria-placeholder="$t('accountManagement.oldPassword')"
                name="oldPwd"
                :type="pwdType1"
                auto-complete="on"
              />
              <span
                class="show-pwd"
                tabindex="0"
                @keyup.enter="showPwd(1)"
                @click="showPwd(1)"
              >
                <em :class="pwdType1 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
              </span>
            </el-form-item>
            <el-form-item prop="newPwd">
              <span class="svg-container">
                <em class="ri-lock-fill" />
                {{ $t('general.newPwd') }}
              </span>
              <el-input
                ref="refpwd2"
                v-model="pwdInfoForm.newPwd"
                name="newPwd"
                :type="pwdType2"
                :placeholder="$t('accountManagement.newPassword')"
                :aria-placeholder="$t('accountManagement.newPassword')"
                auto-complete="on"
              />
              <span
                class="show-pwd"
                tabindex="0"
                @keyup.enter="showPwd(3)"
                @click="showPwd(2)"
              >
                <em :class="pwdType2 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
              </span>
              <div class="pwd-notice">
                {{ $t('accountManagement.PasswordRule') }}
              </div>
            </el-form-item>
            <el-form-item prop="confirmNewPwd">
              <span class="svg-container">
                <em class="ri-lock-fill" />
                {{ $t('general.confirmPwd') }}
              </span>
              <el-input
                ref="refpwd3"
                v-model="pwdInfoForm.confirmNewPwd"
                name="confirmNewPwd"
                :type="pwdType3"
                :placeholder="$t('accountManagement.confirmNewPassword')"
                :aria-placeholder="$t('accountManagement.confirmNewPassword')"
                auto-complete="on"
              />
              <span
                class="show-pwd"
                tabindex="0"
                @keyup.enter="showPwd(3)"
                @click="showPwd(3)"
              >
                <em :class="pwdType3 === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
              </span>
            </el-form-item>
            <el-row>
              <el-col :span="12" class="btn-return">
                <el-button
                  :loading="loading"
                  @click.native.prevent="handleClose"
                >
                  {{ $t('general.Cancel') }}
                </el-button>
          
              </el-col>
              <el-col :span="12">
                <el-button
                  :loading="loading"
                  @click.native.prevent="submitchangePWD('pwdInfoForm')"
                >
                  {{ $t('general.Save') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </template>
    <!-- 登入 -->
    <template v-else>
      <div class="login-container">
        <div
          class="title-container"
        >
          <h1>
            <img
              :src="logos"
              class="image"
              :alt="$t('Dashboard.SportsDataCharityService')"
            >
          </h1>
        </div>
        <div :class="['form-container', role]">
          <div class="role-select">
            <el-button
              label="consumer"
              type="button"
              :class="['role-button', 'consumer', activeRoleButton === 'consumer' ? 'active' : '']"
              value="consumer"
              @click="handleRoleSelect('consumer')"
            >
              {{ $t(`Organization.consumer`) }}
            </el-button>
            <el-button
              label="provider"
              type="button"
              :class="['role-button', 'provider', activeRoleButton === 'provider' ? 'active' : '']"
              value="provider"
              @click="handleRoleSelect('provider')"
            >
              {{ $t(`Organization.provider`) }}
            </el-button>
          </div>
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            auto-complete="on"
            :label-position="labelPosition"
          >
            <el-form-item prop="membername" :label="$t('general.Account')">
              <span class="svg-container" aria-hidden="true">
                <em class="ri-user-3-fill" />
              </span>
              <el-input
                v-model="loginForm.membername"
                :placeholder="$t('general.Account')"
                :aria-placeholder="$t('general.Account')"
                type="text"
                name="membername"
                auto-complete="on"
              />
            </el-form-item>
            <el-form-item prop="pwd" :label="$t('general.Password')">
              <span class="svg-container" aria-hidden="true">
                <em class="ri-lock-fill" />
              </span>
              <el-input
                :key="pwdType"
                ref="refpwd"
                v-model="loginForm.pwd"
                :type="pwdType"
                :placeholder="$t('general.Password')"
                :aria-placeholder="$t('general.Password')"
                name="pwd"
                auto-complete="on"
                @keyup.enter.native="handleLogin"
              />
              <span
                class="show-pwd"
                tabindex="0"
                @keyup.enter="showPwd"
                @click="showPwd"
              >
                <em :class="pwdType === 'password' ? 'ri-eye-close-line' : 'ri-eye-fill'" />
              </span>
            </el-form-item>
            <el-form-item class="verify-container" prop="verificationCode" :label="$t('general.Code')">
              <span class="svg-container" aria-hidden="true">
                <em class="ri-lock-fill" />
              </span>
              <el-input
                v-model="loginForm.verificationCode"
                :placeholder="$t('general.VerificationCode')"
                name="verificationCode"
                @keyup.enter.native="handleLogin"
              />
              <Verify ref="verifyInLogin" />
            </el-form-item>
            <ForgetPassword />
            <el-row>
              <el-col :xs="24" :sm="12" class="btn-return">
                <el-button
                  type="button"
                  :loading="loading"
                  @click.native.prevent="handleClose"
                >
                  {{ $t('general.Cancel') }}
                </el-button>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-button
                  type="button"
                  :loading="loading"
                  @click.native.prevent="handleLogin"
                >
                  {{ $t('general.Login') }}
                </el-button>
              </el-col>
            </el-row>
          </el-form>
          <div class="no-account-container">
            <span>
              {{ $t('Register.NoAccount') }}
            </span>
            <router-link :to="{ name: 'Register' }" class="register-now">
              {{ $t('general.RegisterNow') }}
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { updateUser } from '@/api/member'
import { Resize, Versions, SuccessMessage } from '@/Mixins'

import 'element-ui/lib/theme-chalk/display.css'

import CheckTermsVersion from '@/components/Login/CheckTermsVersion'
import Verify from '@/components/Verify'
import ForgetPassword from './components/ForgetPassword'

export default {
  name: 'LoginPage',
  components: {
    ForgetPassword,
    CheckTermsVersion,
    Verify
  },
  mixins: [Resize, Versions, SuccessMessage],
  data () {
    const pwdInfoForm = () => ({
      oldPwd: '',
      newPwd: '',
      confirmNewPwd: ''
    })
    const validatePassword = (rule, value, callback) => {
      if (value.length < 8) {
        callback(new Error(this.$t('RuleMsg.PasswordLimit')))
      } else if (this.pwdInfoForm.oldPwd === value) {
        callback(new Error(this.$t('RuleMsg.DifferentNewPassword')))
      } else {
        callback()
      }
    }
    const checkRepeatPwd = (rule, value, callback) => {
      if (value !== this.pwdInfoForm.newPwd) {
        callback(new Error(this.$t('RuleMsg.PasswordNotSame')))
      } else {
        callback()
      }
    }
    return {
      logos: require('@/assets/images/logos_text_color.png'),
      isCheckTermsOfUse: [], // api: bool // for ui, modify it when fetch api
      dialogVisible_forgetPassword: false,
      loginForm: {
        membername: '',
        pwd: '',
        verificationCode: ''
      },
      pwdInfoForm: pwdInfoForm(),
      loginRules: {
        membername: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('general.PleaseInput') + ' ' + this.$t('general.Account')
          }
        ],
        pwd: [{ required: true, trigger: 'blur', validator: validatePassword }],
        verificationCode: [{
          required: true,
          message: this.$t('general.VerificationCodeEmpty'),
          trigger: 'blur'
        }]
      },
      pwdRules: {
        newPwd: [
          { 
            required: true,
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])^[\w!@#$%^&*()+|{}\[\]`~\-'";:/?.\\>,<]{8,20}$/,
            message: this.$t('RuleMsg.Invalid') + this.$t('RuleMsg.Password'),
            trigger: 'blur'
          },
          { validator: validatePassword, trigger: 'blur' }
        ],
        confirmNewPwd: [
          { required: true, message: this.$t('RuleMsg.InputRepeatPwd'), trigger: 'blur' },
          { validator: checkRepeatPwd, trigger: 'blur' }
        ],
        oldPwd: [
          { required: true, message: this.$t('RuleMsg.PleaseInput') + this.$t('RuleMsg.Password'), trigger: 'blur' }
        ]
      },
      roles: [
        'consumer', 'provider'
      ],
      role: 'consumer',
      activeRoleButton: 'consumer',
      loading: false,
      pwdType: 'password',
      pwdType1: 'password',
      pwdType2: 'password',
      pwdType3: 'password',
      memberId_changePassword: null,
      checkTermsVersion: false,
      forceChangePwd: false,
      // rwd
      isPhone: false
    }
  },
  computed: {
    ...mapGetters(['memberId']),
    labelPosition() {
      return this.isPhone ? 'top' : 'right'
    },
    verificationCode() {
      return this.$refs.verifyInLogin.code
    }
  },
  methods: {
    showPwd(value) {     
      if (value === 1) {
        if (this.pwdType1 === 'password') {
          this.pwdType1 = ''
        } else {
          this.pwdType1 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.refpwd1.focus()
        })
      } else if (value === 2) {
        if (this.pwdType2 === 'password') {
          this.pwdType2 = ''
        } else {
          this.pwdType2 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.refpwd2.focus()
        })
      } else if (value === 3) {
        if (this.pwdType3 === 'password') {
          this.pwdType3 = ''
        } else {
          this.pwdType3 = 'password'
        }
        this.$nextTick(() => {
          this.$refs.refpwd3.focus()
        })
      } else {
        if (this.pwdType === 'password') {
          this.pwdType = ''
        } else {
          this.pwdType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.refpwd.focus()
        })
      }
    },
    handleLogin() {
      if (!this.isPassVerify()) {
        this.$message({
          title: this.$t('general.Error'),
          message: this.$t('Notify.VerificationCodeError'),
          type: 'error'
        })
        return
      }
      this.$refs.loginForm.validate((valid) => {
        if (!valid) return
        this.loading = true
        const sendData = {
          membername: this.loginForm.membername,
          password: this.loginForm.pwd
        }
        this.$store
          .dispatch('user/login', sendData)
          .then(async (response) => {
            const res = response.data
            await this.$store.dispatch('user/getInfo')
            if (res.is_check_terms_of_use !== 'Checked') { // Checked 已勾選, Uncheck 沒勾過, Expired 已勾選但過期
              this.checkTermsVersion = true
            } else {
              this.$router.push({ name: 'Home' })
            }
          })
          .catch((e) => {
            const errorData = e.response.data
            if (errorData.error) {
              if (errorData.error.code === 2011) {
                this.forceChangePwd = true
                this.memberId_changePassword = errorData.error.details.member_id
              } else if (errorData.error.code === 2004) {
                this.$alert(this.$t('Notify.lockIPAfter5Error'), this.$t('general.Notify'), {
                  confirmButtonText: this.$t('general.Confirm')
                })
              }
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async handleRouterPush() {
      await this.$store.dispatch('user/getInfo')
      this.$router.push({ name: 'Home' })
    },
    handleRoleSelect(role) {
      this.role = role
      this.activeRoleButton = this.role
    },
    async handleUpdateMember(type) {
      let newObj = {}
      switch (type) {
        case 'changePWD':
          newObj = {
            password: this.pwdInfoForm.newPwd,
            old_password: this.pwdInfoForm.oldPwd
          }
          break
        case 'checkTerms':
          newObj = {
            terms_version: this.version_member_infoagree_agree
          }
          break
        default:
          break
      }
      this.fetchUpdateMember(type, newObj)
    },
    async fetchUpdateMember(type, value) {
      switch (type) {
        case 'changePWD':
          try {
            await updateUser(this.memberId_changePassword, value)
            this.showSuccessMessage(this.$t('Notify.UpdatedReLogin'))
            this.logout()
            this.forceChangePwd = false
            this.pwdInfoForm.oldPwd = ''
            window.location.reload()
          } catch (error) {
            console.error(error)
          }
          break
        case 'checkTerms':
          try {
            await updateUser(this.memberId, value)
            this.$router.push({ name: 'Home' })
          } catch (error) {
            console.error(error)
          }
          break
        default:
          break
      }
    },
    async logout() {
      await this.$store.dispatch('user/logout')
    },
    submitchangePWD(pwdInfoForm) {
      this.$refs[pwdInfoForm].validate((valid) => {
        if (valid) {
          this.handleUpdateMember('changePWD')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    isPassVerify() {
      return this.loginForm.verificationCode.toUpperCase() === this.verificationCode
    },
    handleClose() {
      this.$router.push({ name: 'SportData' })
    },
    handleResize() {
      this.$nextTick(() => {
        this.isPhone = window.innerWidth < 768
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
$light_gray: #eee;

.login-outer-container{
  height: 100vh;
  .form-container{
    padding: 30px 50px;
    color: $text_light;
    width: fit-content;
    margin: 0 auto;
    @media (max-width: 576px) {
      padding: 8px 0;
    }
  }
  .change-container{
    margin-top: 10%;
    .form-container.change-password{
      background-color: $background_dark;
      color: $text_light;
    }
    .svg-container {
      width: 100px;
    }
    .change-form{
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      .el-button {
        width: 100%;
      }
      .el-form-item{
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 31, 7, 0.1);
        border-radius: 5px;
        color: #454545;
        padding-top: 7px;
        .el-input {
          display: inline-block;
          height: 47px;
          width: 70%;
          ::v-deep .el-input__inner:focus {
            border: 3px solid $input_focus_gold !important;
          }
        }
      }
      .pwd-notice{
        color: #f3f2f1;
        font-size: 13px;
        padding-left:10px;
      }
      ::v-deep .el-form-item__label{
        color:#fff;
      }
    }
  }
  .login-container {
    height: 100vh;
    background:url(../../assets/images/background/login_background_desk.png) top center no-repeat;
    background-size: cover; //contain
    @media (max-width: 576px) {
      background: initial;
    }
    .form-container.consumer{
      background-color: $physicalFitness_turquoise;
    }
    .form-container.provider{
      background-color: $sport_yellow;
    }
    .role-select{
      .role-button{
        color: $text_light;
        border: 3px solid $text_light;
      }
      .role-button.consumer{
        background-color: $theme_consumer;
      }
      .role-button.provider{
        background-color: $sport_yellow;
      }
      .role-button.active{
        background-color:$background_light;
        color: $text_dark;
      }
    }
    .login-form {
      width: 500px;
      max-width: 100%;
      margin: 0 auto;
      @media (max-width: 576px) {
        width: 90vw;
        padding: 8px;
      }
      .el-button {
        width: 100%;
      }
      .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 19, 31, 0.1);
        border-radius: 5px;
        color: #454545;
        padding-top: 7px;
        @media (max-width: 576px) {
          background: initial;
          padding-top: 0;
        }
        .el-input {
          display: inline-block;
          height: 47px;
          width: 75%;
          @media (max-width: 576px) {
            width: 100%;
          }
          ::v-deep .el-input__inner:focus {
            border: 3px solid $input_focus_gold !important;
          }
        }

        input {
          background: transparent;
          border: 0;
          -webkit-appearance: none;
          border-radius: 5px;
          padding: 12px 5px 14px 15px;
          color: $light_gray;
          height: 47px;
          caret-color: $text_light;

          &:-webkit-autofill {
            box-shadow: 0 0  1000px $background_dark inset !important;
            -webkit-text-fill-color: $text_light !important;
          }
        }
      }
      ::v-deep .el-form-item__label{
        color:#fff;
        @media (max-width: 576px) {
          color: initial;
        }
      }
    }
    .no-account-container{
      padding-top: 5px;
      font-size: 0.875rem;
      @media (max-width: 576px) {
        line-height: 3;
        text-align: center;
      }
      .register-now:hover{
        background-color: rgba(0, 19, 31, 0.3);
      }
    }
  }
  .verify-container{
    margin-bottom: 0;
  }
  // 共用
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .title {
      font-size: 1.625rem;
      line-height: 2;
      text-align: center;
      font-weight: 800;
      letter-spacing: 3px;
      margin: 0;
    }
  .title-container {
    padding-top: 7vh;
    @media (max-width: 576px) {
      padding-top: 0;
    }
    .image {
      margin: 0 auto;
      height: 80px;
      display: block;
      @media (min-width: 1201px){
        height: 100px;
      }
    }
  }
  .show-pwd {
    position: absolute;
    right: 30px;
    top: 0px;
    font-size: 16px;
    color: $light_gray;
    cursor: pointer;
    user-select: none;
    @media (max-width: 576px) {
      color: initial;
    }
  }
  .svg-container {
    color: $light_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .role-select{
    text-align: center;
    padding-bottom: 1rem;
  }
  .el-button {
    background-color: $background_dark;
    color: $text_light;
    font-weight: normal;
  }
  .el-button:hover {
    font-weight: bold;
  }
  .el-button:focus{
    border: 3px solid $input_focus_gold ;
  }
  .btn-return .el-button{
    background-color: $info;
  }
  .btn-return{
    @media (max-width: 576px) {
      margin-bottom: 8px;
    }
  }
}
</style>
