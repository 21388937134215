<template>
  <div class="info-agree-content-text">
    <div class="section-h2 subtitle">
      {{ $t('InfoaAree.Title_Subject') }}
    </div>
    <span class="version subtitle">{{ $t(`accountManagement.version`)}}：{{ version_member_infoagree_agree }}</span>
    <div class="content-section">
      <div class="content-desc">
        {{ $t('InfoaAree.Desc_Subject') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('InfoaAree.Title_Section1') }}</div>
      <div class="content-desc">
        {{ $t('InfoaAree.Desc1_Section1') }}
      </div>
      <div class="content-desc">
        {{ $t('InfoaAree.Desc2_Section1') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('InfoaAree.Title_Section2') }}</div>
      <div class="content-desc">
        {{ $t('InfoaAree.Desc_Section2') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('InfoaAree.Title_Section3') }}</div>
      <div class="content-desc">
        {{ $t('InfoaAree.Desc_Section3') }}
      </div>
      <ol>
        <li>{{ $t('InfoaAree.Desc1_Section3') }}</li>
        <li>{{ $t('InfoaAree.Desc2_Section3') }}</li>
        <li>{{ $t('InfoaAree.Desc3_Section3') }}</li>
        <li>{{ $t('InfoaAree.Desc4_Section3') }}</li>
        <li>{{ $t('InfoaAree.Desc5_Section3') }}</li>
      </ol>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('InfoaAree.Title_Section4') }}</div>
      <div class="content-desc">
        {{ $t('InfoaAree.Desc_Section4') }}
      </div>
    </div>
    <div class="content-section">
      <div class="content-title">{{ $t('InfoaAree.Title_Section5') }}</div>
      <div class="content-desc">
        {{ $t('InfoaAree.Desc_Section5') }}
      </div>
      <ol>
        <li>{{ $t('InfoaAree.Desc1_Section5') }}</li>
        <li>{{ $t('InfoaAree.Desc2_Section5') }}</li>
      </ol>
    </div>
  </div>
</template>

<script>
import { Versions } from '@/Mixins'

export default {
  name: 'PersonalInfoAgree',
  mixins: [Versions]
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.info-agree-content-text{
  word-break: normal;
  .subtitle{
    padding-left: 10px;
  }
  .section-h2{
    color: black;
  }
  .version{
      font-size: 0.875rem;
      color: $danger;
    }
  .content-section{
    padding: 0.625rem;
    width: 100%;
    .content-title{
      font-weight: bold;
    }
    li{
      line-height: 1.5;
    }
  }
}
</style>
